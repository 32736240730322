import { useCompanyStore } from '../stores/company';

export default ({ to, from }) => {
  const companyStore = useCompanyStore();

  if (to?.name === 'companies-create' && from?.name !== 'companies-create') {
    companyStore.clearCompany();
  }

  if (from?.name?.includes('companies-id') && !to?.name?.includes('companies')) {
    companyStore.clearCompany();
  }
};
