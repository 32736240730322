import { defineStore } from 'pinia';
import { useRouteQuery } from '@vueuse/router';

// eslint-disable-next-line import/prefer-default-export
export const useActionStore = defineStore('action', {
  state: () => ({
    loadingActions: true,
    loadingCounts: true,
    filters: {
      status: useRouteQuery('status', 'active', { mode: 'push' }),
      from: useRouteQuery('from', null, { mode: 'push' }),
      priority: useRouteQuery('priority', null, { mode: 'push' }),
      important: useRouteQuery('important', false, { mode: 'push', transform: Boolean }),
      parentType: useRouteQuery('parent_type', null, { mode: 'push' }),
      childType: useRouteQuery('child_type', null, { mode: 'push' }),
      term: null,
      sortField: useRouteQuery('sort', 'priority', { mode: 'push' }),
      sortDirection: useRouteQuery('direction', 'desc', { mode: 'push' }),
    },
    currentPage: useRouteQuery('page', 1, { mode: 'push', transform: Number }),
    selectedActions: [],
    selectedCategory: false,
    bulkOperation: null,
    actions: null,
    counts: null,
    showBulkOperationModal: false,
    currentAction: null,
  }),
  getters: {
    activeTabCount(state) {
      const { parentType, childType } = state.filters;
      if (!parentType && !childType) {
        return state.counts?.active.total;
      } if (parentType && !childType) {
        return state.counts?.active.counts[parentType].total;
      }
      return state.counts?.active.counts[parentType].children[childType];
    },
    resolvedTabCount(state) {
      const { parentType, childType } = state.filters;
      if (!parentType && !childType) {
        return state.counts?.resolved.total;
      } if (parentType && !childType) {
        return state.counts?.resolved.counts[parentType].total;
      }
      return state.counts?.resolved.counts[parentType].children[childType];
    },
  },
  actions: {
    fetchActions() {
      this.loadingActions = true;
      this.selectedActions = [];
      this.selectedCategory = false;
      this.bulkOperation = null;
      const url = this.$config.endpoints.companies.action_index
        .replace('{company_id}', this.$router.currentRoute.params.id);
      this.$axios.get(url, {
        params: {
          status: this.filters.status,
          parent_type: this.filters.parentType,
          child_type: this.filters.childType,
          from: this.filters.from ?? undefined,
          priority: this.filters.priority ?? undefined,
          important: Number(this.filters.important) ?? undefined,
          term: this.filters.term ?? undefined,
          sort: this.filters.sortField ?? undefined,
          direction: this.filters.sortDirection ?? undefined,
          page: this.currentPage,
        },
      }).then((response) => {
        this.actions = response.data.data;
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      }).finally(() => {
        this.loadingActions = false;
      });
    },
    fetchCounts() {
      this.loadingCounts = true;
      this.counts = null;
      const url = this.$config.endpoints.companies.action_counts
        .replace('{company_id}', this.$router.currentRoute.params.id);
      this.$axios.get(url, {
        params: {
          from: this.filters.from ?? undefined,
          priority: this.filters.priority ?? undefined,
          important: Number(this.filters.important) ?? undefined,
          term: this.filters.term ?? undefined,
        },
      }).then((response) => {
        this.counts = response.data.data;
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      }).finally(() => {
        this.loadingCounts = false;
      });
    },
    changeType(parentType = null, childType = null) {
      this.filters.parentType = parentType;
      this.filters.childType = childType;
    },
    selectAllActions() {
      if (this.selectedActions.length === this.actions.data.length) {
        this.selectedActions = [];
        this.selectedCategory = false;
      } else {
        this.selectedActions = this.actions.data.map((action) => action.id);
      }
    },
    resolveAction(action, resolution = null) {
      const url = this.$config.endpoints.bulk_action.resolve
        .replace('{company_id}', this.$router.currentRoute.params.id);
      this.$axios.post(url, {
        ids: [action.id],
        status: this.filters.status,
        resolution: resolution ?? undefined,
      }).then((response) => {
        this.$toast(response.data.message, 'success');
        this.removeActionFromTable(action);
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      });
    },
    restoreAction(action) {
      const url = this.$config.endpoints.bulk_action.restore
        .replace('{company_id}', this.$router.currentRoute.params.id);
      this.$axios.post(url, {
        ids: [action.id],
        status: this.filters.status,
      }).then((response) => {
        this.$toast(response.data.message, 'success');
        this.removeActionFromTable(action);
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      });
    },
    removeActionFromTable(action, changeOtherStatusCount = true) {
      const index = this.actions.data.findIndex((value) => value.id === action.id);
      this.actions.data.splice(index, 1);
      this.actions.total -= 1;

      // If there's nothing left and there's still actions just refresh
      if (this.actions.data.length === 0 && this.actions.current_page < this.actions.last_page) {
        this.refreshActions();
        return;
      }

      // Also update the counts
      const updateCounts = (status, change) => {
        this.counts[status].total += change;
        this.counts[status].counts[action.parent_type].total += change;
        if (['credential', 'phishing', 'traffic'].includes(action.parent_type)) {
          this.counts[status].counts[action.parent_type].children[action.source] += change;
        } else {
          // eslint-disable-next-line max-len
          this.counts[status].counts[action.parent_type].children[action.child_type] += change;
        }
      };

      updateCounts(this.filters.status, -1);
      if (changeOtherStatusCount) {
        const otherStatus = this.filters.status === 'active' ? 'resolved' : 'active';
        updateCounts(otherStatus, +1);
      }
    },
    refreshActions() {
      this.fetchActions();
      this.fetchCounts();
    },
  },
});
